





































































































































































































































































































.day-planning-table__non-mobile-row {
  display: table-row;
}

.day-planning-table__mobile-row {
  display: none;
}

@media screen and (max-width: 600px) {
  .day-planning-table__non-mobile-row {
    display: none;
  }

  .day-planning-table__mobile-row {
    display: table-row;
  }
}

.day-planning-table__reports-cell {
  white-space: nowrap;
}

.day-planning-table__reports-cell__has-reports {
  cursor: pointer;
  text-decoration: underline;
}

.day-planning-table__reports-cell__no-reports {
  color: rgb(166, 166, 166);
  font-style: italic;
}
