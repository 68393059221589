
















































































































































































































































































































































































































































































































































































































































































































































































































































































































.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
.clickable {
  cursor: pointer !important;
}
.nclickable {
  cursor: default !important;
}
