














































































































































































































































































































.related-documents__icon {
  position: relative;
  bottom: 5px;
}

.related-documents__title {
  vertical-align: bottom;
}
