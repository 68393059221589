
































































































.confirmation-dialog__icon {
  font-size: 4rem;
}

.confirmation-dialog__title {
  word-break: break-word;
}
