






































































































.label {
  font-size: 14px;
  font-weight: 400;
  color: #888;
}

.activities-table__disabled {
  color: rgba(0, 0, 0, 0.38);
}
