


















































































.document-tile--disabled {
  cursor: no-drop;
}
.document-tile {
  cursor: pointer;
}
