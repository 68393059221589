




























.label {
  font-size: 14px;
  font-weight: 400;
  color: #888;
}

.label-high {
  line-height: 32px;
}
