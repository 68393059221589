
















































































































































.label {
  font-size: 14px;
  font-weight: 400;
  color: #888;
}

.products-table__non-mobile-row {
  display: table-row;
}

.products-table__mobile-row {
  display: none;
}

@media screen and (max-width: 600px) {
  .products-table__non-mobile-row {
    display: none;
  }

  .products-table__mobile-row {
    display: table-row;
  }
}
