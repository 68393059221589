

































































































.iframe-container {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative;
}

.iframe-container-justify {
  overflow: hidden;
  position: relative;
  height: 65vh;
  width: 70%;
}

.iframe-container iframe,
.iframe-container-justify iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
