
































































































































































































.label {
  font-size: 14px;
  font-weight: 400;
  color: #888;
}

.technique-steps {
  font-size: 0.8rem;
}
