






































































































































































































































.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}

.my-inspections-table__non-mobile-row {
  display: table-row;
}

.my-inspections-table__mobile-row {
  display: none;
}

@media screen and (max-width: 600px) {
  .my-inspections-table__non-mobile-row {
    display: none;
  }

  .my-inspections-table__mobile-row {
    display: table-row;
  }
}
