





































































































































































































































































.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
.my-dossiers-table__non-mobile-row {
  display: table-row;
}

.my-dossiers-table__mobile-row {
  display: none;
}

@media screen and (max-width: 600px) {
  .my-dossiers-table__non-mobile-row {
    display: none;
  }

  .my-dossiers-table__mobile-row {
    display: table-row;
  }
}
