

























































































































































































































































.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
.lots-table__non-mobile-row {
  display: table-row;
}

.lots-table__mobile-row {
  display: none;
}

.lots__qualified-employees_none {
  font-style: italic;
  color: gray;
}

.lots__qualified-employees_multiple {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .lots-table__non-mobile-row {
    display: none;
  }

  .lots-table__mobile-row {
    display: table-row;
  }
}
