


































































































.dayplanning-info {
  font-size: 0.8em;
}
