








































































































































.v-treeview-node__root.v-treeview-node--active {
  pointer-events: none;
  border: 2px solid red;
}
