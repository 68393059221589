



















































































.expansion-panel-content-no-padding {
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
