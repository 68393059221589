
























































































































































































































.my-documents__icon {
  position: relative;
  bottom: 5px;
}

.my-documents__title {
  vertical-align: bottom;
}
