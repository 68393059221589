<template>
  <div v-if="entry && exchangeDefinition && exchange && !loadingExchange">
    <div>
      <div
        v-for="(step, index) in exchangeDefinition.steps"
        v-bind:key="step.id"
        class="exchange-step"
        @click="setShowExchangeDialog(step)"
      >
        <div
          class="d-flex flex-column flex-md-row align-center"
          v-if="exchangeSteps[step.id]"
        >
          <v-icon
            class="mt-1 mb-1 me-4"
            style="width: 25px"
            :class="{
              'primary--text': exchangeSteps[step.id].state == 'COMPLETED',
              'inprogress-icon-color':
                exchangeSteps[step.id].state == 'INPROGRESS',
            }"
          >
            {{ `mdi-numeric-${index + 1}-box` }}
          </v-icon>
          <p class="mt-1 mb-1 me-4" style="width: 350px">{{ $t(step.name) }}</p>
          <v-chip
            v-if="exchangeSteps[step.id].state == 'COMPLETED'"
            color="primary"
            text-color="white"
            class="
              mt-1
              mb-1
              me-4
              d-flex
              align-content-center
              justify-center
              text-dots
            "
            style="width: 120px"
          >
            {{ exchangeSteps[step.id].state }}
          </v-chip>
          <v-chip
            v-else-if="exchangeSteps[step.id].state == 'INPROGRESS'"
            color="#a9c43c"
            text-color="white"
            class="
              mt-1
              mb-1
              me-4
              d-flex
              align-content-center
              justify-center
              text-dots
            "
            style="width: 120px"
          >
            {{ !rules.canEditExchange ? 'CANCELLED' : exchangeSteps[step.id].state }}
          </v-chip>
          <v-chip
            v-else
            class="
              mt-1
              mb-1
              me-4
              d-flex
              align-content-center
              justify-center
              text-dots
            "
            style="width: 120px"
          >
            {{ !rules.canEditExchange ? 'CANCELLED' : exchangeSteps[step.id].state }}
          </v-chip>
          <v-btn
            text
            class="mt-1 mb-1 me-4"
            style="width: 100px"
            @click="setShowExchangeDialog(step)"
          >
            {{ $t("general.open") }}
          </v-btn>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showExchangeDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      :eager="true"
      width="fit-content"
    >
      <v-card>
        <v-card-title class="text-md-h5 grey lighten-2">
          {{ $t(exchangeDefinition.name) }}
        </v-card-title>
        <v-col>
          <exchange-component v-if="showExchangeDialog"
            :readonly="!rules.canEditExchange"
            :exchange-definition-id="this.metadata.exchangeDefinitionId"
            :exchange-id="exchangeId"
            :external-context="externalContext"
            :involved-parties="this.metadata.involvedParties"
            :reference-id="this.metadata.referenceId"
            :reference-type="this.metadata.referenceType"
            @controlbus="recieveControlBus"
            @exchangeControl="recieveExchangeControl"
          />
        </v-col>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div v-else>
    <v-skeleton-loader
      v-bind="attrs"
      type="text, divider, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar"
      :min-width="650"
      :min-height="300"
    ></v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { Component, Prop } from "vue-property-decorator";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import Loader from "@/components/common/Loader.vue";
import { RenderType } from "@/app/Types";
import ExchangeComponent from "@/app/components/exchange/exchange-component.vue";
import {
  Exchange,
  ExchangeControl,
  ExchangeEvent,
  ExchangeStep,
} from "@/app/components/exchange/exchange.model";
import {
  DynamicDetailEntry,
  ExchangeDefinition,
  ExchangeDefinitionStep,
} from "@/app/dynamic-components/details/dynamic-detail.model";
import { exchangeService } from "@/app/services/exchange.service";
import { Subject, Subscription } from "rxjs";
import { ExternalContext } from "@/app/contexts/externalContext";

export type DetailComponentExchangeStepsMetaData = {
  exchangeDefinitionId: string;
  involvedParties: { [key: string]: string };
  referenceId: string;
  referenceType: string;
};

@Component({
  computed: {
    RenderType() {
      return RenderType;
    },
  },
  components: { ExchangeComponent, Loader, FormWrapper, OverviewComponent },
})
export default class DetailComponentExchangeSteps extends DetailComponentBase<DetailComponentExchangeStepsMetaData> {
  protected externalContext = new ExternalContext();

  get attrs() {
    return {
      class: "mb-6",
      boilerplate: false,
      elevation: 2,
    };
  }

  @Prop({ default: null })
  exchangeId!: string | null;

  showExchangeDialog = false;
  private controlbus: Subject<ExchangeEvent> | null = null;
  private controlbusSubscription: Subscription | null = null;
  exchangeDefinition: ExchangeDefinition = {} as ExchangeDefinition;
  protected exchange: Exchange | null = null;

  private exchangeSteps: { [key: string]: ExchangeStep } = {};

  private loadingExchange = true;

  async postEntityChanged(newValue: DynamicDetailEntry) {
    this.loadingExchange = true;
    if (newValue && this.entry) {
      if (this.metadata.exchangeDefinitionId) {
        //get stepper data
        this.exchangeDefinition = await exchangeService.getExchangeDefinition(
          this.metadata.exchangeDefinitionId
        );
        await this.fetchOrPlaceholderExchange();
      }
    }
  }

  setShowExchangeDialog(step: ExchangeDefinitionStep) {
    this.externalContext = this.externalContext.inherit(this.detailContext);
    this.showExchangeDialog = true;
    setTimeout(()=>{
      if (this._exchangeControl) {
        this._exchangeControl.setStepPosition(step.id);
      }
    },1000);
  }

  private _exchangeControl: ExchangeControl | undefined;
  recieveExchangeControl(exchangeControl: ExchangeControl) {
    this._exchangeControl = exchangeControl;
  }

  recieveControlBus(controlbus: Subject<ExchangeEvent>) {
    if (this.controlbusSubscription) {
      this.controlbusSubscription.unsubscribe();
    }
    this.controlbus = controlbus;
    this.controlbusSubscription = this.controlbus.subscribe((event) => {
      switch (event.action) {
        case "CLOSE":
          this.showExchangeDialog = false;
          this.fetchOrPlaceholderExchange();
          break;
      }
    });
  }

  private async fetchOrPlaceholderExchange() {
    this.loadingExchange = true;
    await exchangeService
      .getFirstExchange(
        this.metadata.exchangeDefinitionId,
        this.metadata.referenceId
      )
      .then((value) => {
        if (value) {
          this.exchange = value;
          this.exchangeId = value.id;
        } else {
          this.exchange = ExchangeComponent.CONSTRUCT_EXCHANGE(
            this.exchangeDefinition
          );
          this.exchangeId = null;
        }
        this.exchangeSteps = {};
        this.exchange?.steps.forEach((value) => {
          this.exchangeSteps[value.id] = value;
        });
        this.loadingExchange = false;
      })
      .catch((reason) => {
        this.$toast.error("Failed to fetch current steps for dossier");
        this.exchangeSteps = {};
      });
  }
}
</script>
<style scoped>
.inprogress-icon-color {
  color: #a9c43c !important;
  caret-color: #a9c43c !important;
}
.exchange-step {
  transition: all 250ms;
  cursor: pointer;
  &:hover {
    background: rgba(225, 225, 225, 0.5);
  }
}
</style>
