





















































































































































































































































































































































































































































































































.documents-table__non-mobile-row {
  display: table-row;
}

.documents-table__mobile-row {
  display: none;
}

.documents-table__document-name {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .documents-table__non-mobile-row {
    display: none;
  }

  .documents-table__mobile-row {
    display: table-row;
  }
}
